<template>
	<main class="main-content mt-0">
		<section>
			<div class="page-header min-vh-100">
				<div class="container">
					<div class="row">
						<div
							class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
						>
							<div class="card card-plain">
								<div class="card-header pb-0 text-start">
									<div class="row">
										<div class="col-12 d-flex align-items-center">
											<img
												class="text-center"
												src="@/assets/logo.png"
												style="width: 90%"
												alt="LOGO"
											/>
										</div>
										<div class="col-11 text-end">
											<h4 class="font-weight-bolder text-center">
												Unique Transaction ID Portal
											</h4>
										</div>
									</div>
								</div>
								<div class="card-body">
									<Form
										@submit="handleLogin"
										:validation-schema="schema"
										role="form"
									>
										<div class="mb-3">
											<Field
												name="email"
												type="email"
												class="form-control form-control-lg"
												placeholder="Email Address..."
											/>
											<ErrorMessage
												name="email"
												class="text-danger py-3 text-sm"
											/>
										</div>
										<div class="mb-3">
											<Field
												name="password"
												type="password"
												class="form-control form-control-lg"
												placeholder="Password"
											/>
											<ErrorMessage
												name="password"
												class="text-danger py-3 text-sm"
											/>
										</div>
										<div class="text-center">
											<div class="form-group py-2">
												<div
													v-if="message"
													class="alert alert-danger text-white"
													role="alert"
												>
													{{ message }}
												</div>
											</div>
											<button
												class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0"
												:disabled="loading"
											>
												<span>Sign In</span>
												<span
													v-show="loading"
													class="spinner-border spinner-border-sm ml-2"
												></span>
											</button>
										</div>
									</Form>
								</div>
							</div>
						</div>
						<div
							class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
						>
							<div
								class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center bg_image overflow-hidden"
							>
								<span class="mask bg-gradient-primary opacity-6"></span>
								<h4
									class="mt-5 text-white font-weight-bolder position-relative"
								>
									Innovative Toll
								</h4>
								<p class="text-white position-relative">
									Form a relationship with a great toll management solution
									provider and set yourself for success in the changing Tolling
									space.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
	import { Form, Field, ErrorMessage } from "vee-validate";
	import * as yup from "yup";

	export default {
		name: "SignIn",
		components: { Form, Field, ErrorMessage },
		data() {
			const schema = yup.object().shape({
				email: yup
					.string("Email is required!")
					.required("Email is required!")
					.email("Email is invalid!")
					.max(50, "Must be maximum 50 characters!"),
				password: yup
					.string("Password is required!")
					.required("Password is required!"),
			});
			return {
				loading: false,
				message: "",
				schema,
			};
		},
		methods: {
			async handleLogin(user) {
				this.loading = true;
				this.message = "";

				try {
					const response = await this.$store.dispatch("auth/login", user);
					if (response.access_token) {
						this.$router.push("/dashboard-uni");
					} else {
						this.loading = false;
						this.message = response.error;
					}
				} catch (error) {
					this.loading = false;
					this.message = "Incorrect Credentials.";
				}
			},
		},
	};
</script>

<style scoped>
	.bg_image {
		background-image: url(https://crm.innovativetoll.com/assets/images/login_bg.png);
		background-size: cover;
	}
</style>
